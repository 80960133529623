import "antd/dist/reset.css";
import "./App.css";

import { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ErrorBoundary from "./component/common/ErrorBoundary";
import Dashboard from "./page/Dashboard";
import Pipeline from "./page/Pipeline";
import Account from "./page/Account";
import Root from "./page/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "pipeline",
        element: <Pipeline />,
      },
      {
        path: "account",
        element: <Account />,
      }
    ],
  },
]);

const App: FC = () => (
  <ErrorBoundary>
    <RouterProvider router={router} fallbackElement={<div>Error</div>} />
  </ErrorBoundary>
);

export default App;
