import { red } from "@ant-design/colors";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

type TProps = {
  children: ReactNode;
  style?: React.CSSProperties;
};

const ErrorBox: FC<TProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

const Container = styled.div`
  border-radius: 2px;
  background-color: ${red[2]};
  padding: 16px;
`;

export default ErrorBox;
