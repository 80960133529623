import { FC } from "react";
import AppLayout from "../component/common/AppLayout";
import { homeBreadcrumb } from "../component/common/Breadcrumb";

type TProps = {};

const Pipeline: FC<TProps> = () => {
    const loading = false;
    const error = "";

  return (
    <AppLayout
      breadcrumbs={[
        homeBreadcrumb,
        {
          key: "pipeline",
          label: "Pipeline",
        },
      ]}
      loading={loading}
      error={error}
    >
      <h3>Account</h3>
      
    </AppLayout>
  );
};

export default Pipeline;
