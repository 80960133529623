import { blue } from "@ant-design/colors";
import { Col, Row } from "antd";
import { FC, ReactNode } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import AppLayout from "../component/common/AppLayout";
import { homeBreadcrumb } from "../component/common/Breadcrumb";
import useWindowDimensions from "../utils/WindowDimensionHook";
import { RootContentContext } from "./RootContext";

type TProps = {};

const Root: FC<TProps> = () => {
  const location = useLocation();
  const { width, height } = useWindowDimensions();

  let content: ReactNode;
  if (location.pathname === "/") {
    content = (
      <AppLayout breadcrumbs={[homeBreadcrumb]}>
        <Row gutter={16}>
          <Col span={8}>
            <Link to="/dashboard">
              <Block>Dashboard</Block>
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/pipeline">
              <Block>Pipeline</Block>
            </Link>
          </Col>
          <Col span={8}>
            <Link to="/account">
              <Block>Account</Block>
            </Link>
          </Col>
        </Row>
      </AppLayout>
    );
  } else {
    content = <Outlet />;
  }

  return (
    <RootContentContext.Provider
      value={{
        width,
        height: height,
      }}
    >
      {content}
    </RootContentContext.Provider>
  );
};

const Block = styled.div`
  height: 300px;
  border-radius: 4px;
  background-color: ${blue[2]};
  padding: 16px;
  font-weight: bold;
  font-size: 1.2em;
`;

export default Root;
