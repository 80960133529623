import { Button, Layout } from "antd";
import React, { CSSProperties, FC, ReactNode } from "react";
import { RootContentContext } from "../../page/RootContext";
import { useRefSize } from "../../utils/RefSizeHook";
import useWindowDimensions from "../../utils/WindowDimensionHook";
import { Breadcrumbs, TBreadcrumb } from "./Breadcrumb";
import { blue } from "@ant-design/colors";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import ErrorBox from "./ErrorBox";
type TProps = {
  header?: ReactNode;
  children?: ReactNode;
  breadcrumbs?: TBreadcrumb[];
  style?: CSSProperties;
  noPadding?: boolean;
  loading?: boolean;
  error?: string | null;
  reload?: () => void;
};

export const AppLayoutContext = React.createContext<{
  contentHeight: number;
}>(undefined as any);

const AppLayout: FC<TProps> = ({
  header,
  breadcrumbs,
  children,
  style = {},
  noPadding = false,
  loading = false,
  error = null,
  reload,
}) => {
  const { width, height } = useWindowDimensions();
  const { height: topHeight, ref: topRef } = useRefSize<HTMLDivElement>();
  return (
    <RootContentContext.Provider
      value={{
        width,
        height: height,
      }}
    >
      <AppLayoutContext.Provider value={{ contentHeight: height - topHeight }}>
        <Layout style={style}>
          <div
            ref={topRef}
            style={{ backgroundColor: blue[1], display: "flex" }}
          >
            <div style={{ padding: 16, flex: 1 }}>
              {header}
              {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
            </div>
            <div style={{ padding: 16 }}>{loading && <LoadingOutlined />}</div>
            <div style={{ padding: 16 }}>
              {!loading && reload && (
                <Button
                  onClick={reload}
                  type="text"
                  shape="circle"
                  icon={<ReloadOutlined />}
                />
              )}
            </div>
          </div>
          {error && <ErrorBox>{error}</ErrorBox>}
          <div style={{ padding: noPadding ? 0 : 16 }}>{children}</div>
        </Layout>
      </AppLayoutContext.Provider>
    </RootContentContext.Provider>
  );
};

export default AppLayout;
