import { Button, Result } from "antd";
import {
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

type TProps = {
  children: ReactNode;
};

const ErrorBoundary: FC<TProps> = ({ children }) => {
  const [error, setError] = useState("");

  const promiseRejectionHandler = useCallback(
    (event: { reason: SetStateAction<string> }) => {
      setError(event.reason);
    },
    []
  );

  const resetError = useCallback(() => {
    setError("");
  }, []);

  useEffect(() => {
    window.addEventListener("unhandledrejection", promiseRejectionHandler);

    return () => {
      window.removeEventListener("unhandledrejection", promiseRejectionHandler);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return error ? (
    <Result
      status="500"
      title="Error"
      subTitle={error.toString()}
      extra={
        <Button type="primary" onClick={resetError}>
          Reset
        </Button>
      }
    />
  ) : (
    <div>{children}</div>
  );
};

export default ErrorBoundary;
