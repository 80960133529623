export class APIError extends Error {
  statusCode = 400;
  code?: string;

  constructor(message: string) {
    super(message);

    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, APIError.prototype);
  }
}

export async function callAPI<T>(
  func: string,
  params: Record<string, any> = {}
): Promise<T> {
  const fetchOptions: any = { credentials: "same-origin", method: "POST" };
  // const fetchOptions: any = { method: "POST" };


  if (params instanceof FormData) {
    fetchOptions.body = params;
  } else if (params) {
    fetchOptions.body = JSON.stringify(params);
    fetchOptions.headers = {
      "Content-Type": "application/json"
    };
  }

  let response;
  try {
    response = await fetch(apiUrl(func), fetchOptions);
  } catch (e) {
    // console.log(e);
    throw new APIError(String(e));
  }

  if (response.status !== 200) {
    let message: string;
    let code;
    if (response.status === 400) {
      const data = await response.json();
      if (data.ok === false) {
        message = data.message as string;
        code = data.code as string;
      } else {
        message = `Unknown error: ${JSON.stringify(data)}`;
      }
    } else if (response.status === 500) {
      message = "Could not connect to API";
    } else {
      message = `Unknown error: ` + (await response.text());
    }

    const error = new APIError(message);
    error.statusCode = response.status;
    if (code) {
      error.code = code;
    }
    throw error;
  }

  return (await response.json()) as T;
}



export const apiUrl = (func: string) => {
  return `${process.env.REACT_APP_API_HOST || ""}/api/${func}`;
};
