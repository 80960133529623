import { useState } from "react";

import { callAPI } from "../APIUtils";
import { useDeepCompareEffect } from "./DeepCompareEffectHook";

export function useAPIData<T>(
  func: string,
  params: Record<string, any>,
  initData: T
): [T, boolean, string | null, () => Promise<void>] {
  const [data, setData] = useState<T>(initData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function loadData() {
    // console.log("call ", func, params);
    setLoading(true);
    setError(null);
    try {
      const nextData = await callAPI<T>(func, params);
      setData(nextData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(String(e));
    }
  }
  useDeepCompareEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, params]);

  return [data, loading, error, loadData];
}

export function useAPICall<T>(
  func: string,
  initData: T
): [T, boolean, string | null, (params: Record<string, any>) => Promise<void>] {
  const [data, setData] = useState<T>(initData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function loadData(params: Record<string, any>) {
    // console.log("call ", func, params);
    setLoading(true);
    setError(null);
    try {
      const nextData = await callAPI<T>(func, params);
      setData(nextData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(String(e));
    }
  }

  return [data, loading, error, loadData];
}
