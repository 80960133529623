import { isEqual } from "lodash";
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  effect: EffectCallback,
  deps?: DependencyList
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, deps ? deps.map(useDeepCompareMemoize) : deps);
}
