import { FC } from "react";
import AppLayout from "../component/common/AppLayout";
import { homeBreadcrumb } from "../component/common/Breadcrumb";
import { callAPI } from "../utils/APIUtils";
import { useAPIData } from "../utils/hook/APIHook";

type TProps = {};
type TDashboard = {
  type: string,
  filename: string
}

const Dashboard: FC<TProps> = () => {

    const [data, loading, error] = useAPIData<TDashboard[]|null>(
      "dashboard_get",
      {},
      null
    );
  
    console.log(data);
    if (!data) {
      return null;
    }


  return (
    <AppLayout
      breadcrumbs={[
        homeBreadcrumb,
        {
          key: "dashboard",
          label: "Dashboard",
        },
      ]}
      loading={loading}
      error={error}
    >
      <h3>Dashboard</h3>
      {data.map((record, key) => (
        <div key={key}>{record.type}  {record.filename}</div>
      ))}
      
    </AppLayout>
  );
};

export default Dashboard;
