import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export type TBreadcrumb = {
  label: ReactNode;
  key: string;
};

export const homeBreadcrumb = {
  key: "home",
  label: (
    <Link to="/">
      <HomeOutlined /> Home
    </Link>
  ),
};

export const Breadcrumbs: FC<{
  items: TBreadcrumb[];
}> = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item) => (
        <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
