import { useEffect, useRef, useState } from "react";

export const useRefSize = <T extends Element>() => {
  const [height, setHeight] = useState(0);
  const ref = useRef<T>(null);
  useEffect(() => {
    setHeight(ref.current ? ref.current.clientHeight : 0);
  }, [ref]);

  return { height, ref };
};
